/* eslint "sort-keys": ["warn", "asc", {"caseSensitive": false, "natural": false, "minKeys": 2}] */
export default {
  CartButtons: {
    addToCart: 'Add to Cart',
    publishNow: 'Publish Now',
  },
  GuideFeedbackModal: {
    bodyMessage:
      'Please be as specific as possible. Thank you for your feedback!',
    modalHeader: 'Why are you going to ControlPoint Classic?',
    sendButton: 'Send feedback and go to Classic',
    skipButton: 'Skip and go to Classic',
    successMessage: 'Going to Classic...',
    successMessageBold: 'Thank you! ',
  },
  Help: {
    callLabel: 'or call',
    createTicketButton: 'Create a ticket',
    helpHeader: 'Help',
    helpOnlineLabel: 'HELP Online',
    phoneLabel: '1-800-232-2677',
  },
  History: {
    addedToCartSubtitle: 'History of all changes added to the cart',
    changeText: 'Change:',
    pastChangesSubtitle:
      'History of all changes successfully published or sent to the store from the cart',
    searchLabel: 'Search',
    sectionLabel: 'Section',
    showMoreButtonLabel: 'Show more',
    title: 'History',
    updatedByText: 'Updated by:',
  },
  Home: {
    caresSupportTiersLabel: 'CARES Support Tiers',
    conceptTypeLIC: 'CONCEPT_TYPE_LIC',
    feedbackButtonLabel: 'Give feedback',
    header: 'ControlPoint',
  },
  MaintenanceMode: {
    bodyText: 'Automatically refreshing in {{counter}}',
    header: 'ControlPoint is currently down for maintenance',
    refreshButtonText: 'Refresh now',
    subText: 'We’re working on a fix and will be back online shortly.',
  },
  SelectStore: {
    next: 'Next',
    prev: 'Previous',
    selectStore: 'Find your store',
    selectStoreSearchInputPlaceholder: 'Search store name or number',
    storeRange:
      '{{startingStoreNumber}} - {{endingStoreNumber}} of {{totalStores}}',
  },
  SystemTools: {
    commandContactHostButton: 'Initiate download',
    commandContactHostError: 'Error downloading',
    commandContactHostLoading: 'Initiating...',
    commandContactHostPromptConfirm: 'Initiate download',
    commandContactHostPromptSubtitle:
      'Are you sure you want to initiate a ServicePoint EP download at the store?',
    commandContactHostPromptTitle: 'Contact Host',
    commandContactHostSubtitle:
      'Remotely triggers the store server to initiate a ServicePoint EP download',
    commandContactHostSuccess: 'Downloaded',
    commandContactHostTitle: 'Contact Host',

    commandGlobalDataButton: 'Fetch item data',
    commandGlobalDataError: 'Error fetching item data',
    commandGlobalDataLoading: 'Fetching...',
    commandGlobalDataSubtitle:
      'Fetches dynamic data (local items, item overrides, etc.) from the store',
    commandGlobalDataSuccess: 'Data received',
    commandGlobalDataTitle: 'Global Config',

    commandPollButton: 'Send status check',
    commandPollError: 'Error sending status check',
    commandPollLoading: 'Sending...',
    commandPollSubtitle: 'Performs a status check of the POS Agent Service',
    commandPollSuccess: 'Status check sent',
    commandPollTitle: 'Poll',

    commandPromptModalCancelButton: 'Cancel',
    commandPromptModalMessage: 'KPS and POS reboots are required',

    commandRelayGenButton: 'Re-generate and send',
    commandRelayGenError: 'Error regenerating/sending relay files',
    commandRelayGenLoading: 'Re-generating',
    commandRelayGenPromptConfirm: 'Re-generate and send',
    commandRelayGenPromptSubtitle:
      'Are you sure you want to generate and send ALL relay files to the store?',
    commandRelayGenPromptTitle: 'Relay Gen',
    commandRelayGenSubtitle:
      'Generates and sends ALL relay files to the store (requires a KPS and POS reboot)',
    commandRelayGenSuccess: 'Data sent',
    commandRelayGenTitle: 'Relay Gen',

    commandResendDataButton: 'Re-send data',
    commandResendDataError: 'Error resending data',
    commandResendDataLoading: 'Re-sending...',
    commandResendDataPromptConfirm: 'Re-send',
    commandResendDataPromptSubtitle:
      'Are you sure you want to re-send the latest data to the store?',
    commandResendDataPromptTitle: 'Re-send Data',
    commandResendDataSubtitle:
      'Re-sends ControlPoint data to store (requires a KPS and POS reboot)',
    commandResendDataSuccess: 'Data sent',
    commandResendDataTitle: 'Re-send Data',

    commandSyncReferenceDataButton: 'Fetch reference data',
    commandSyncReferenceDataError: 'Error fetching reference data',
    commandSyncReferenceDataLoading: 'Fetching...',
    commandSyncReferenceDataSubtitle:
      'Fetches static data (items, device groups, destinations, etc.) from the store',
    commandSyncReferenceDataSuccess: 'Reference data received',
    commandSyncReferenceDataTitle: 'Reference Data',

    generateTerminalNumbersSPFBButton: 'Generate',
    generateTerminalNumbersSPFBError:
      'Error generating terminal numbers for SPFB',
    generateTerminalNumbersSPFBLoading: 'Generating...',
    generateTerminalNumbersSPFBSubtitle:
      'Generates terminal numbers for SPFB devices at the store',
    generateTerminalNumbersSPFBSuccess: 'Terminal numbers generated for SPFB',
    generateTerminalNumbersSPFBTitle: 'Generate SPFB Terminal Numbers',

    generateTerminalNumbersVPButton: 'Generate',
    generateTerminalNumbersVPError: 'Error generating terminal numbers for VP',
    generateTerminalNumbersVPLoading: 'Generating...',
    generateTerminalNumbersVPSubtitle:
      'Generates terminal numbers for VP devices at the store',
    generateTerminalNumbersVPSuccess: 'Terminal numbers generated for VP',
    generateTerminalNumbersVPTitle: 'Generate VP Terminal Numbers',

    header: 'System Tools',
    lastPolledStatus: 'Last Polled: {{date}}',

    resendOverridesButton: 'Re-send',
    resendOverridesError: 'Error resending overrides',
    resendOverridesLoading: 'Re-sending...',
    resendOverridesSubtitle: 'Resends item override data to the store',
    resendOverridesSuccess: 'Data sent',
    resendOverridesTitle: 'Resend Overrides',

    resendPINOverridesButton: 'Resend PIN Overrides',
    resendPINOverridesError: 'Error resending PIN overrides',
    resendPINOverridesLoading: 'Re-sending...',
    resendPINOverridesSubtitle:
      'Resends pricing PIN Overrides from MenuDNA to the Store',
    resendPINOverridesSuccess: 'Data sent',
    resendPINOverridesTitle: 'Resend PIN Overrides',

    statusEmptyText: 'There are no statuses to show',

    statusTruncatedEllipsisText: '{{text}}...',
    statusTruncatedTextHideButton: 'Hide',
    statusTruncatedTextViewButton: 'View all',
  },
};
